import { useContext, useEffect, useState } from 'react'
import { Section } from '../../components'
import {
  ValidatedInput,
  DateInput,
  EmailInput,
  PhoneInput,
  SocialInput,
  DollarsInput,
  RoutingNumberInput,
  IntegerInput,
  DateOfBirthInput,
  LicenseIssueDateInput,
  LicenseExpirationDateInput
} from '../../components/forms'
import { FormSchema, FormInputProps, pause, Org } from '@oneethos/shared'
import './consumer-application-form.scss'
import {
  dollars,
  extractDate,
  errorToString,
  LoanApplication,
  mmddyyyy,
  PreSubmitStatus,
  PreSubmitStatusOrder,
  RawDecisionProResponseBody
} from '@oneethos/shared'
import { useAppState, useUtmQuery } from '../../hooks'
import api from '../../api-client'
import { Spinner, SpinnerSize } from '@fluentui/react'
import ApplicationMenu from './menu'
import { Eligibility } from './eligibility'
import InitialLoanRequest from './initial-loan-request'
import ContactInformation from './contact-information'
import Employment from './employment'
import Coborrower from './coborrower'
import IncomeVerification from './income-verification'
import AutoDebitAuthorization from './auto-debit-authorization'
import { SubmitApplication } from './submit-application'
import { Submitted } from './submitted'
import { Navigate, useParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import actions from '../../actions'
import { AppContext } from '../../appContext'
import { formDataToLoanApp, lappToFormData } from './util'

// This approach is slightly problematic because there are several other fields that require custom 
// treatment, so this is only a partial list
const schema: FormSchema = {
  loanAmount: {
    label: 'Loan Amount',
    type: 'dollars'
  },
  avgMonthlyUtilityBill: {
    label: 'Average Monthly Utility Bill',
    type: 'dollars'
  },
  customerName: {
    label: 'Full Name',
  },
  firstName: {
    label: 'First Name',
  },
  lastName: {
    label: 'Last Name',
  },
  ssn: {
    label: 'Social Security Number',
    type: 'ssn'
  },
  phone: {
    label: 'Moblie Phone (must validate via SMS)',
    type: 'phone'
  },
  email: {
    label: 'Email',
    type: 'email'
  },
  birthDate: {
    label: 'Birth Date',
    type: 'dob'
  },
  driversLicenseIssueDate: {
    label: 'Drivers License Issue Date',
    type: 'licenseIssue'
  },
  driversLicenseExpirationDate: {
    label: 'Drivers License Expiration Date',
    type: 'licenseExpiration'
  },
  driversLicenseNo: 'Drivers License Number',
  driversLicenseIssuingState: 'Drivers License Issuing State',
  statedGrossAnnualIncome: {
    type: 'dollars',
    label: 'Annual Income (before taxes)'
  },
  statedHouseholdIncome: {
    type: 'dollars',
    label: 'Annual Household Income (before taxes)'
  },
  citizenship: 'Citizenship',
  userAddress: {
    label: 'Home Address',
    type: 'address'
  },

  // employment
  employerName: 'Employer Name',
  occupation: 'Occupation/Title',

  // autodebit
  autoDebitInstitution: 'Depository Institution',
  autoDebitRoutingNo: {
    label: 'Routing Number',
    type: 'routing'
  },
  autoDebitAccountNo: 'Account Number'
}

// TODO: This should really be called SchemaInput and only used in a schema context
export const FormInput = ({ field, ...props }: FormInputProps) => {
  const config = schema[field]
  let component
  switch (config.type) {
    case 'ssn': component = <SocialInput
      {...props}
      onChange={s => {
        const val = s.trim().replace(/[^0-9]/g, '')
        if (val.length < 4) return props.onChange(val)
        if (val.length < 6) {
          return props.onChange(`${val.slice(0, 3)}-${val.slice(3)}`)
        }

        props.onChange(`${val.slice(0, 3)}-${val.slice(3, 5)}-${val.slice(5, 9)}`)
      }}
    />; break
    case 'phone': component = <PhoneInput {...props} />; break
    case 'email': component = <EmailInput {...props} />; break
    case 'integer': component = <IntegerInput {...props} />; break
    case 'routing': component = <RoutingNumberInput {...props} />; break
    case 'dollars': component = <DollarsInput {...props}/>; break
    case 'dob': component = (<DateOfBirthInput {...props} />); break
    case 'licenseIssue': component = (<LicenseIssueDateInput {...props} />); break
    case 'licenseExpiration': component = (<LicenseExpirationDateInput {...props} />); break  
    case 'date':
      component = (
        <DateInput
          {...props}
          onChange={s => {
            let val = typeof s !== 'string' ? '' : s
            if (!/^[0-9]{1,2}(\/|\/[0-9]{1,2}|\/[0-9]{1,2}\/[0-9]{0,4})?$/.test(val)) {
              val = val.trim().replace(/[^0-9]/g, '')
              if (val.length > 2 && val.length < 5) {
                val = `${val.slice(0, 2)}/${val.slice(2)}`
              } else if (val.length >= 5) {
                val = `${val.slice(0, 2)}/${val.slice(2, 4)}/${val.slice(4, 8)}`
              }
            }

            props.onChange(val)
          }}
          onBlurFormatter={val => {
            const d = extractDate(val || '')
            return d && d.toString() !== 'Invalid Date' ? mmddyyyy(d) : val
          }}
        />
      )
      break
    default: component = <ValidatedInput
      {...props}
      validator={s => s ? '' : 'required'}
    />
  }

  return <div className="form-group">
    <label>{(typeof config === 'string' ? config : config.label) || field}</label>
    {component}
  </div>
}

const steps: Record<PreSubmitStatus, any> = {
  'Loan Request': InitialLoanRequest,
  'Eligibility': Eligibility,
  'Contact Information': ContactInformation,
  'Employment': Employment,
  'Co-Borrower': Coborrower,
  'Income Verification': IncomeVerification,
  'Auto-Debit Authorization': AutoDebitAuthorization,
  'Submit Application': SubmitApplication,
  'Submitting': SubmitApplication
}

type SaveProps = {
  data?: Partial<LoanApplication> & { testPrequalResult: 'pass' | 'fail' | 'no-hit' },
  advance?: boolean,
  onCatch?: (e: string | Error) => void
}

export type SubformProps = {
  formData: Partial<LoanApplication> & { loanAmount: string, avgMonthlyUtilityBill: string }
  org: Org
  update: (data: string | Record<string, unknown>, value?: unknown) => void
  save: (s?: SaveProps) => Promise<void>
  onError: (err: string) => void
  onSubmit: () => void
  onTestSubmit: (code: string) => void
  saving: boolean
  schema: FormSchema
}

export const Validation = ({ validation }) => {
  const entries = Object.entries(validation)
  return entries.length ? <div className="caf-error alert alert-danger">
    {entries.map(([field, error], i) => <div key={i}>
      {error}
    </div>)}
  </div> : null
}

export const ConsumerApplicationForm = ({ id, token }) => {
  const { dispatch, state: { registration: { authError } } } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [step, setStep] = useState<PreSubmitStatus | ''>('')
  const [org, setOrg] = useState()
  const [formData, setFormData] = useState<Partial<LoanApplication>>({
    preSubmitStatus: PreSubmitStatusOrder[0]
  })

  // only add the token if it's passed to prevent compexity in router auth
  const tauth = token ? `?t=${token}` : ''

  const fetchLoanApp = async () => {
    return api.get(`/c/loanapps/${id}${tauth}`).then(({ lapp, org }) => {
      setOrg({ operatingState: [], ...org })

      setFormData(lappToFormData(lapp))

      if (lapp.incentive) {
        dispatch(actions.setCobranding(lapp.incentive))
      }

      return lapp
    })
  }

  const pollForDecision = () => {
    const poll = async () => {
      try {
        const { lapp } = await api.get(`/c/loanapps/${id}${tauth}`)
        if (lapp.preSubmitStatus === 'Submitting') {
          await pause(1.5)
          poll()
        } else {
          setFormData(lappToFormData(lapp))
        }
      } catch (ex) {
        handleError(ex)
      }
    }

    poll()
  }

  useEffect(() => {
    fetchLoanApp().then(lapp => {
      if (lapp.preSubmitStatus === 'Submitting') {
        pollForDecision()
      }
    }).catch(ex => {
      dispatch(actions.setAuthError(ex.code || errorToString(ex)))
    }).finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    setStep('')
  }, [formData.preSubmitStatus])

  const update = (data: string | object, value?: any) => {
    if (typeof data === 'string') {
      setFormData({
        ...formData,
        [data]: value
      })
    } else if (typeof data === 'object') {
      setFormData({ ...data })
    }
  }

  const save = async (data): Promise<LoanApplication> => {
    const lapp = formDataToLoanApp(data)
    return api.patch(`/c/loanapps/${formData._id}${tauth}`, lapp)
  }

  const handleError = (ex) => {
    if (Array.isArray(ex.error)) {
      toast.error(
        <div>
          <div>Please fix the following problems:</div>
          {ex.error.map((err, i) => <div key={i}>{err}</div>)}
        </div>
        , { autoClose: false })
    } else {
      const msg = errorToString(ex)

      toast.error(msg, { autoClose: false })
    }
  }

  if (loading) {
    return <Section>
      <Spinner />
    </Section>
  }

  if (authError) {
    const query = new URLSearchParams({ route: `/apply/${id}` })
    const url = `/login?${query.toString()}`
    return <Navigate to={url} />
  }

  if (formData.preSubmitStatus === 'Submitting') {
    return <div className="submitting">
      <h3>Your application is being submitted</h3>
      <p>This can take a few moments...</p>
      <Spinner size={SpinnerSize.large} style={{ margin: '2em' }} />
    </div>
  } else if (formData.preSubmitStatus === 'Submitted') {
    return <Submitted formData={formData} update={update} />
  }

  if (formData.error === 'SYSTEM_ERROR') {
    return <div className="alert alert-danger">
      There was an error processing your application. We apologize for the inconvenience.
      Please reach out to a support representative for assistance completing your application.
    </div>
  }

  const Content = steps[step || formData.preSubmitStatus]

  return <div className='consumer-application-form'>
    <ApplicationMenu
      maxStep={formData.preSubmitStatus}
      onNav={setStep}
      statuses={PreSubmitStatusOrder.filter(s => s !== 'Submitted')}
      currentStep={step || formData.preSubmitStatus}
    />
    <div className="caf-content">
      {formData.error || formData.submissionResponse?.error ? <div>
        <div className="alert alert-danger">
          {formData.error || formData.submissionResponse.error}
        </div>
      </div> : null}
      <Content
        formData={formData}
        org={org}
        update={update}
        saving={saving}
        onError={handleError}
        schema={schema}
        save={async (opts) => {
          const {
            data,
            advance = true, // continue to the next step by default
            onCatch         // override catch handler; otherwise will default to setting error
          } = opts || {}

          const _then = lapp => {
            const nextData = { ...lapp }
            if (advance) {
              const idx = PreSubmitStatusOrder.findIndex(s => s === (step || nextData.preSubmitStatus))
              nextData.preSubmitStatus = PreSubmitStatusOrder[idx + 1]
            }
            setStep('')
            setFormData({
              ...nextData,
              loanAmount: dollars(nextData.loanAmount?.toString() || '0'),
              avgMonthlyUtilityBill: (
                nextData.avgMonthlyUtilityBill ?
                  dollars(nextData.avgMonthlyUtilityBill?.toString()) :
                  undefined
              )
            })
          }

          setSaving(true)
          if (onCatch) {
            return save(data || formData)
              .then(_then)
              .catch(onCatch)
              .finally(() => setSaving(false))
          } else {
            return save(data || formData)
              .then(_then)
              .catch(handleError)
              .finally(() => setSaving(false))
          }
        }}
        onSubmit={async () => {
          try {
            setFormData({ ...formData, preSubmitStatus: 'Submitting' })
            await save(formData)
            const lapp = await api.post(`/c/loanapps/${id}/submit`, {})
            setFormData({ ...lapp })
            pollForDecision()
          } catch (ex) {
            setFormData({ ...formData, preSubmitStatus: 'Submit Application' })
            handleError(ex)
          }
        }}
        onTestSubmit={async (code: string) => {
          try {
            await save(formData)
            setFormData({ ...formData, preSubmitStatus: 'Submitting' })
            await pause(3)

            const success: Partial<RawDecisionProResponseBody> = {
              DecisionCode: code
            }

            if (code === 'A') {
              success.MonthlyPayment = '123.45 (test only, not actually calculated)'
            }

            update({
              ...formData,
              preSubmitStatus: 'Submitted',
              submissionResponse: {
                success
              }
            })
          } catch (ex) {
            handleError(ex)
          }
        }}
      />
    </div>
  </div>
}

export const ConsumerApplicationPage = () => {
  const query = useUtmQuery()
  const { id } = useParams()
  const { tenant } = useAppState()

  let payload
  if (query.t) {
    try {
      payload = jwtDecode(query.t)
    } catch (ex) {
      return <Section>
        <div className="alert alert-danger">
          Error initializing page: {ex.message}
        </div>
      </Section>
    }
  }

  if (payload?.id && id && payload.id !== id) {
    return <Section>
      <div className="alert alert-danger">
        Could not load application. Please try using the original
        link you received to begin the application.
      </div>
    </Section>
  }

  if (!id && !payload?.id) {
    return <Section>
      To provide you with the best service and ease of install, we
      require all applicants to work with a {tenant.config.name} Approved
      Preferred Solar Installer. Please contact us at
      {tenant.config.bankEmail} to request information on a
      {tenant.config.name} Approved Preferred Solar Installer in your area.
    </Section>
  }

  return <Section>
    <ConsumerApplicationForm
      id={id || payload?.id}
      token={query.t}
    />
  </Section>
}

export default ConsumerApplicationPage
