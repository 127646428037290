import React, { useState, useRef } from 'react'
import { TextInputWithErrorProps, TextInputProps, dollars, dollarsToNum } from '@oneethos/shared'
import { Validate } from '@oneethos/shared'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export * from './address-inputs'

export const ValidatedInput = ({
  value,
  onChange,
  onBlurFormatter,
  placeholder,
  validator,
  emptyOk,
  ...props
}: TextInputWithErrorProps) => {
  const [blurred, setBlurred] = useState(0)
  const ref = useRef<HTMLInputElement>(null)
  let error = ''

  if (blurred && validator && document.activeElement !== ref.current) {
    // don't show the error if user is actively modifying
    error = (emptyOk && !value) ? '' : validator(value)
  }

  return <div className="validated-input">
    <input
      {...props}
      type="text"
      ref={ref}
      className={`form-control ${error ? 'error' : ''}`}
      // increment blur count to trigger a re-render after blur to set error if invalid
      onBlur={ev => {
        setBlurred(b => b + 1)
        if (onBlurFormatter) {
          onChange(onBlurFormatter(value))
        }

        if (props.onBlur) {
          props.onBlur(ev)
        }
      }}
      value={value || ''}
      aria-label={placeholder || 'enter value'}
      placeholder={placeholder}
      onChange={ev => onChange(ev.target.value)}
    />
    {error ? <p className="small error">{error}</p> : null}
  </div>
}

export const TextInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={s => !s ? 'Required' : ''}
  />
}

export const DateInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidDate}
    placeholder={'mm/dd/yyyy'}
  />
}

export const EmailInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidEmail}
    placeholder={'example@domain.com'}
  />
}

export const PhoneInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidPhone}
    placeholder={'7191234567'}
  />
}

export const SocialInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidSocial}
    placeholder={'000-00-0000'}
  />
}

export const DateOfBirthInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidDOB}
    placeholder={'mm/dd/yyyy'}
  />
}

export const LicenseExpirationDateInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidLicenseExpirationDate}
    placeholder={'mm/dd/yyyy'}
  />
}

export const LicenseIssueDateInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidLicenseIssueDate}
    placeholder={'mm/dd/yyyy'}
  />
}

export const DollarsInput = (props: TextInputProps) => {
  return <ValidatedInput
    placeholder={'$10,000'}
    {...props}
    validator={Validate.isValidDollars}
    onChange={s => {
      // onChange is called after onBlurFormatter, so it has to respect the formatting from that function
      // instead of just using dollars(dollarsToNum(val), 2); plus using that here causes input to lose cursor position
      const val = s?.trim().replace(/[^$0-9.,]/g, '')
      props.onChange(val)
    }}
    onBlurFormatter={val => {
      return val !== undefined ? dollars(dollarsToNum(val), 2) : undefined
    }}

  />
}

export const ZipInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isValidZip}
    placeholder={'12345'}
  />
}

export const RoutingNumberInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isRoutingNumber}
    onChange={s => {
      const val = s.trim().replace(/[^0-9]/g, '')
      props.onChange(val)
    }}
  />
}

export const IntegerInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isInteger}
    onChange={s => {
      const val = s.trim().replace(/[^0-9]/g, '')
      props.onChange(val)
    }}
  />
}

export const NumericInput = (props: TextInputProps) => {
  return <ValidatedInput
    {...props}
    validator={Validate.isNumeric}
    onChange={s => {
      const val = s.trim().replace(/[^0-9.]/g, '')
      props.onChange(val)
    }}
  />
}

type PasswordInputProps = {
  value?: string
  onChange: (s: string) => void
}

export const PasswordInput = (props: PasswordInputProps) => {
  const [show, setShow] = useState(false)

  return <div className="input-group">
    <input
      type={show ? 'text' : 'password'}
      className="form-control"
      value={props.value || ''}
      onChange={ev => props.onChange(ev.target.value)}
    />
    <span
      className="input-group-text"
      onClick={() => setShow(!show)}
    >
      {show ? <FaEye /> : <FaEyeSlash />}
    </span>
  </div>
}
